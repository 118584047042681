.RSVP{
    background-color: white;
    border-bottom: 1px solid;
    border-image: radial-gradient(closest-side at 50% 55%, #000 30%, transparent 50%) 80% 1;
}
.RSVP .rsvpMessage{
    padding-top: 100px;
    align-items: center;
    align-content: center;
    margin-bottom: 150px;
}

.RSVPForm{
    margin-top: 50px;
    display: flex;
    flex-flow: column;
}

.RSVPForm button{
 font-size: 16px;
}

.container{
    display: flex;
    flex-flow: row;
    justify-content: flex-start;
    margin-bottom: 10px;
}

.GuestList .container .AdultGuestList{
    width: 50%;
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    align-items: start;
}

.GuestList .container .ChildrenGuestList{
    width: 50%;
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    align-items: end;
}

.NameInputBox{
    display: flex;
    flex-flow: row;
    padding: 5px 0px;
    
}



.GuestList{
    width: 90%;
    max-width: 850px;
    margin: auto;
    display: flex;
    justify-content: flex-start;
    flex-flow:column;
    text-align: left;
}

.GuestInput{
    width:80%;
    display: flex;
    justify-content: flex-start;
    flex-flow:column;
    text-align: left;
}

.GuestInput label{
    font-size: 12px;
}

.GuestInput input{
    width: 80%;
    height: 45px;
    padding: 10px;
    margin-right: 5px;
}

.AddMember{
    width: 90%;
    height: 40px;
    border: none;
    background-color: var(--websiteColor);
    color:white;
    align-self: center;
    border-radius: 20px;
    margin: 10px 0;

}

.FinishSubmit{
    color: green;
    font-size: max(1.0vw, 2.0vh);
}

.AddMember:hover{
    background-color: var(--websiteHover);
}

.SubmitForm{
    width: 300px;
    height: 50px;
    border: none;
    background-color: var(--websiteColor);
    color:white;
    align-self: start;
    border-radius: 25px;
    margin: 10px 0;

}

.SubmitForm:hover{
    background-color: var(--websiteHover);
}

.GuestInput .DeleteMember{
    border: none;
    border-radius: 5px;
    background-color: var(--websiteColor);
    color:white;
    align-self: center;
    height: 95%;
    width: 20%;
}

.DeleteMember:hover{
    background-color: var(--websiteHover);
}

.Additional{
    min-height: 100px;
    resize: vertical;
    text-align: start;
    padding:5px;
}

.FormElement{
    margin: auto;
    width:90%;
    max-width: 850px;
    display: flex;
    justify-content: flex-start;
    flex-flow:column;
    text-align: left;
}

.FormElement input{
    height: 45px;
    width: 100%;
    padding: 10px;
    margin-right: 5px;
}

.FormElement select{
    height: 45px;
    width: 100%;
    padding: 10px;
    margin-right: 5px;
}

.DietTag{
    background-color: #fcaf58;
    display: inline-block;
    height: 25px;
    align-self: center;
    padding: 2px 6px;
    border-radius: 10px;
    margin: 2px 10px;
    color: white;
}

.DietTag::after{
    display: inline-block;
    content: "\00d7";
}

.Error{
    color:red;
    font-weight: 600;
}

.RSVPFYI{
    margin-top: 50px;
    font-size: max(1.5vw, 2.5vh);
}

.KungFu{
    margin-top: 50px;
    margin-bottom: 50px;
    background-image: url("../QA/KungfuDefeat.jpg");

    /* Set a specific height */
    min-height: 500px; 
  
    /* Create the parallax scrolling effect */
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 20vw;
    overflow: hidden;
    align-self: center;

}
@media(max-width:1200px){
    .KungFu{
        background-attachment: scroll;
    }
}


@media (max-width: 820px){
    .RSVPForm{
        font-size: 12px;
    }

    .RSVPForm button{
        font-size: 12px;
    }

    .RSVPForm .GuestInput label{
        font-size: 10px;
    }

    .RSVPForm .FormElement label{
        font-size: 10px;
    }

    .container{
        flex-flow: column;
    }

    .GuestList .container .AdultGuestList {
        width:100%;
    }

    .GuestList .container .ChildrenGuestList {
        width:100%;
    }

    .GuestInput{
        width:100%;
        display: flex;
        justify-content: flex-start;
        flex-flow:column;
        text-align: left;
    }

    .GuestInput .DeleteMember{
        height: 40px;
    }

    .SubmitForm{
        width: 90%;
        align-self: center;
    }
      


}



/* .container{
    display: flex;
    flex-flow: row;
    justify-content: flex-start;
    margin-bottom: 10px;
}

.GuestList .container .AdultGuestList{
    width: 50%;
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    align-items: start;
}

.GuestList .container .ChildrenGuestList{
    width: 50%;
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    align-items: end;
} */
