.Home{
    height: 100vh;
    background-image: url('./home-pic2.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 100% 75%;
    padding-bottom: 100px;
    box-shadow: 2px 2px 10px black;
}

.HomePic{
    background-image: url('./home-pic2.jpg');
    position: relative;
    width: 100%;
    height: 100vh;
}

.homeMessage {
    padding-top: max(8vw,15vh);
    align-self: center;
    align-content: center;
    z-index: 10;
    position: relative;
    color: white;
    text-shadow: 3px 3px 5px black;
}
.Home p {
    font-size: max(1.5vw,2vh);
}

.Home .homeMessage .gettingMarried{

    font-family: "Parisienne", cursive;
    font-size: max(5vw,5vh);
}

@media (max-width:800px){
    .Home{
        height: 100vh;
        background-position: 60% 75%;

    }    
}