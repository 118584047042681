.Details{
    --color: rgba(30, 30, 30);
    --bgColor: rgba(245, 245, 245);
    color: var(--color);
    border-bottom: 1px solid;
    border-image: radial-gradient(closest-side at 50% 55%, #000 30%, transparent 50%) 80% 1;
}



.Details .detailsMessage{
    padding-top: 100px;
    align-items: center;
    align-content: center;
}


.Details .detailsMessage .itinerary{
    font-size: 1.25em;
    margin: 100px auto auto auto;
    width: 600px;
    display: flex;
    justify-content:center;
}

.event{
    width: 300px;
    text-align: right;
    padding-right: 20px;
    border-right: 2px solid;
}

.eventTime{
    width: 300px;
    text-align: left;
    padding-left: 20px;
    border-left: 2px solid;
}

.dot{
    width: 16px;
    height: 16px;
    background: black;
    border-radius: 50%;
    display: inline-block;
    position: relative;
    left: -101px;
}




@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;500;700&display=swap");


.date,
.date::before,
.date::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.Details ul {
  --col-gap: 2rem;
  --row-gap: 2rem;
  --line-w: 0.10rem;
  display: grid;
  grid-template-columns: var(--line-w) 1fr;
  grid-auto-columns: max-content;
  column-gap: var(--col-gap);
  list-style: none;
  align-self: center;
}

/* line */
.Details ul::before {
  content: "";
  grid-column: 1;
  grid-row: 1 / span 20;
  background: rgb(225, 225, 225);
  border-radius: calc(var(--line-w) / 2);
}

/* columns*/

/* row gaps */
.Details ul li:not(:last-child) {
  margin-bottom: var(--row-gap);
}

/* card */
.Details ul li {
  grid-column: 2;
  --inlineP: 1.5rem;
  margin-inline: var(--inlineP);
  grid-row: span 2;
  display: grid;
  grid-template-rows: min-content min-content min-content;
}

/* date */
.Details ul li .date {
  --dateH: 3rem;
  padding:5px 10px;
  height: var(--dateH);
  margin-inline: calc(var(--inlineP) * -1);

  text-align: center;
  background-color: var(--websiteColor);

  color: white;
  font-size: 15px;
  font-weight: 700;

  display: grid;
  place-content: center;
  position: relative;

  border-radius: calc(var(--dateH) / 2) 0 0 calc(var(--dateH) / 2);
}

/* circle */
.Details ul li .date::after {
  content: "";
  position: absolute;
  width: 2rem;
  aspect-ratio: 1;
  background: var(--bgColor);
  border: 0.3rem solid var(--websiteColor);
  border-radius: 50%;
  top: 50%;

  transform: translate(50%, -50%);
  right: calc(100% + var(--col-gap) + var(--line-w) / 2);
}

/* title descr */

.Details ul li .descr {
  background: var(--bgColor);
  position: relative;
  padding-inline: 1.5rem;
  padding-block-end: 1rem;
  padding-block-start: 1rem;;
  font-weight: 300;

}



/* shadows */

.Details ul li .descr::before {
  content: "";
  position: absolute;
  width: 90%;
  height: 0.5rem;
  background: rgba(0, 0, 0, 0.5);
  left: 50%;
  border-radius: 50%;
  filter: blur(4px);
  transform: translate(-50%, 50%);
}

.Details ul li .descr::before {
  z-index: -1;
  bottom: 0.25rem;
}



.Details .credits {
  margin-top: 1rem;
  text-align: right;
}
.Details .credits a {
  color: var(--color);
}

.DetailsContainer{
    margin-top: 100px;
    max-width: min(80vw,1200px);
    display: flex;
    flex-flow: row;
    justify-content: space-between;
    margin:100px auto;
}

.ManorContainer{
    width:50%;
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
}

.Manor{
  width: 90%;
    align-self: center;
}

.ManorLink{
   color: #fcaf58;
   text-decoration: none;
}

.ManorLink:visited{
    color: #fcaf58;
    text-decoration: none;
}

.ManorDesc{
    width: 90%;
    margin-top: 25px;
    align-self: center;
    
}

.ManorMap{
    margin-top: 50px;
    width: 90%;
    height: 400px;
    align-self: center;
}

.DetailsContainer .icons{
    padding: 5px;
    width: 75px;
    align-self: center;
}


@media (min-width: 821px) {


    .Details ul {
    grid-template-columns: 1fr var(--line-w) 1fr;
    width: 50%;
  }
  .Details ul::before {
    grid-column: 2;
  }
  .Details ul li:nth-child(odd) {
    grid-column: 1;
  }
  .Details ul li:nth-child(even) {
    grid-column: 3;
  }

  /* start second card */
  .Details ul li:nth-child(2) {
    grid-row: 2/4;
  }

  .Details ul li:nth-child(odd) .date::before {
    clip-path: polygon(0 0, 100% 0, 100% 100%);
    left: 0;
  }

  .Details ul li:nth-child(odd) .date::after {
    transform: translate(-50%, -50%);
    left: calc(100% + var(--col-gap) + var(--line-w) / 2);
  }
  .Details ul li:nth-child(odd) .date {
    border-radius: 0 calc(var(--dateH) / 2) calc(var(--dateH) / 2) 0;
  }
}


@media (max-width: 820px) {
  .DetailsContainer{
    display: flex;
    flex-flow: column;
  }

  .ManorContainer{
    width: 100%;
    margin-bottom: 50px;
  }
}