.Nav {
    display: flex;
    justify-content: space-between;
    flex-flow: row;
    height: var(--nav-height);
    align-items: center;
    position: fixed;
    top: 0px;
    transition: height 1s ease, background-color 1s ease, color 1s ease;
    text-shadow: 3px 3px 15px white;
    z-index: 9999;
    width: 100%;

}

.Nav-scrolled {
    height: var(--unfocused-nav-height);
    display: flex;
    justify-content: space-between;
    flex-flow: row;
    /* height: var(--nav-height - 50px); */
    align-items: center;
    background-color: white;
    transition: height 1s ease, background-color 1s ease, color 1s ease;
    position: fixed;
    top: 0px;
    z-index: 9999;
    width: 100%;
    color: var(--websiteColor);
}

.navbar-burger {
    margin-right: 50px;
    cursor: pointer;
    display: none;
    /* Hide the burger menu on larger screens */
}

.line {
    width: 25px;
    height: 3px;
    background-color: var(--websiteColor);
    margin: 5px;
    transition: transform 0.3s, opacity 0.3s;
}

.line.active:nth-child(1) {
    transform: translateY(8px) rotate(45deg);
}

.line.active:nth-child(2) {
    opacity: 0;
}

.line.active:nth-child(3) {
    transform: translateY(-8px) rotate(-45deg);
}


.Logo {
    height: 100%;
    margin-left: 2vw;
}

.LogoContainer {
    height: 100%;
    display: flex;
    flex-flow: row;
    justify-content: center;
}

.LogoContainer:hover {
    cursor: pointer;
}

.PengCrow {
    align-self: center;
    font-size: 20px;
}

@media (max-width: 821px) {

    
    .NavLink{
      display: none; /* Hide the navbar links on smaller screens */
      flex-direction: column;
      justify-content: center;
      align-items: center;
      position: absolute;
      top: 0;
      right: 0;
      height: 100vh;
      width: 50%;
      background-color: white;
      color: var(--websiteColor);
      box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    }

    .NavLink ul {
        margin: 5px;
        display: flex;
        flex-flow: column;
        justify-content: space-around;
        height: 40%;
        
    }

    .NavLink ul li{
        font-size: 2vh;
    }
    .NavLink.active {
      display: flex; /* Show the navbar links when the menu is open */
    }
  
    .navbar-burger {
      display: block; /* Show the burger menu icon on smaller screens */
    }
  }