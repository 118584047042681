.WeddingParty {
    background-color: white;
    border-bottom: 1px solid;
    border-image: radial-gradient(closest-side at 50% 55%, #000 30%, transparent 50%) 80% 1;

}

.WeddingParty .weddingPartyMessage {
    padding-top: 100px;
    align-items: center;
    align-content: center;


}

.PartyContainer {
    margin: 5vh auto;
    margin-bottom: 200px;
    font-size: 25px;
    width: 80vw;
    display: flex;
    flex-flow: column;
    justify-content: space-between;
}

.Individual {
    display: flex;
    flex-flow: column;
    justify-content: space-between;
}


.ParentsCircle {
    width: min(30vw, 300px);
    height: min(30vw, 300px);
    border-radius: 50%;
    overflow: hidden;
    align-self: center;
}

.CrowParents,
.PengParents {
    width: min(30vw, 300px);
}

.PicCircle {
    width: min(20vw, 250px);
    height: min(20vw, 250px);
    border-radius: 50%;
    overflow: hidden;
    align-self: center;
}

.Matt,
.Taha,
.Nat,
.Mika,
.Eggy,
.Tippy {
    width: min(20vw, 250px);
}

.Matt {
    margin: -50px 0 0 0;
}

.Taha {
    margin-top: -30px;
}

.Mika {
    margin-top: -50px;
}

.Eggy {
    margin: -90px 0px 0 0;
}

.Tippy {
    margin: -25px 0px 0 0;
}

.Parents {
    margin-top: 100px;
    width: 80%;
    display: flex;
    flex-flow: row;
    justify-content: space-around;
    align-self: center;
}

.Friends {
    margin-top: 100px;
    width: 80%;
    display: flex;
    flex-flow: row;
    justify-content: space-around;
    align-self: center;
}

.Cats {
    margin-top: 100px;
    width: 80%;
    display: flex;
    flex-flow: row;
    justify-content: space-around;
    align-self: center;
}

/* .Friends{
    display: flex;
    flex-flow: row;
    justify-content: space-between;
    width: 1350px;
    margin: auto;
}

.Cats{
    display: flex;
    flex-flow: row;
    justify-content: space-between;
    width: 1350px;
    margin: auto;
} */

.UsContainer {
    background-image: url("./UsPicture.jpg");

    /* Set a specific height */

    /* Create the parallax scrolling effect */
    background-attachment: fixed;
    background-repeat: no-repeat;
    background-size: contain;
    width: 100%;
    height: 100vh;
    overflow: hidden;
}

.ContainerContainer {
    display: flex;
    flex-flow: row;
    justify-content: center;
}

@media (max-width: 820px) {
    .Parents {
        margin: auto;
        display: flex;
        flex-flow: column;
        justify-content: space-between;
    }

    .Friends {
        margin: auto;
        display: flex;
        flex-flow: column;
        justify-content: space-between;
    }

    .Cats {
        margin: auto;
        display: flex;
        flex-flow: column;
        justify-content: space-between;
    }
    .Individual{
        margin: 20px 0px;
    }



    .ParentsCircle {
        width: 50vw;
        height: 50vw;
        border-radius: 50%;
        overflow: hidden;
        align-self: center;
    }

    .CrowParents,
    .PengParents {
        width: 50vw;
    }

    .PicCircle {
        width: 50vw;
        height: 50vw;
        border-radius: 50%;
        overflow: hidden;
        align-self: center;
    }

    .Matt,
    .Taha,
    .Nat,
    .Mika,
    .Eggy,
    .Tippy {
        width: 50vw;
    }
}